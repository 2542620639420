import React from 'react';

// lib
import i18n from 'i18next';
import { get } from 'lodash';
import { formatNumber } from '../lib/helper';

// styles
import { Dialog } from '@blueprintjs/core';
import infoTableStyles from '../styles/InfoTable.module.css';

const InfoTable = ({ isOpen = false, handleClose, featureProperties }) => {
  if (!isOpen) return null;

  const populationInfo = {
    Geschlechtsverteilung: {
      value: '',
      data: {
        'Einwohner (Anzahl)': {
          val:
            featureProperties.Nummer_Weiblich +
            featureProperties.Nummer_Maennlich,
          percent: undefined,
        },
        Weiblich: {
          val: featureProperties.Nummer_Weiblich,
          percent: featureProperties.Anteil_Weiblich,
        },
        Männlich: {
          val: featureProperties.Nummer_Maennlich,
          percent: featureProperties.Anteil_Maennlich,
        },
      },
    },
    Altersverteilung: {
      value: '',
      data: {
        'Einwohner (Anzahl)': {
          val:
            featureProperties.Nummer_Unter_10 +
            featureProperties.Nummer_10_19 +
            featureProperties.Nummer_20_29 +
            featureProperties.Nummer_30_39 +
            featureProperties.Nummer_40_49 +
            featureProperties.Nummer_50_59 +
            featureProperties.Nummer_60_69 +
            featureProperties.Nummer_70_79 +
            featureProperties.Nummer_80_und_aelter,
          percent: undefined,
        },
        '< 10 Jahre alt': {
          val: featureProperties.Nummer_Unter_10,
          percent: featureProperties.Anteil_Unter_10,
        },
        '10 - 19 Jahre alt': {
          val: featureProperties.Nummer_10_19,
          percent: featureProperties.Anteil_10_19,
        },
        '20 - 29 Jahre alt': {
          val: featureProperties.Nummer_20_29,
          percent: featureProperties.Anteil_20_29,
        },
        '30 - 39 Jahre alt': {
          val: featureProperties.Nummer_30_39,
          percent: featureProperties.Anteil_30_39,
        },
        '40 - 49 Jahre alt': {
          val: featureProperties.Nummer_40_49,
          percent: featureProperties.Anteil_40_49,
        },
        '50 - 59 Jahre alt': {
          val: featureProperties.Nummer_50_59,
          percent: featureProperties.Anteil_50_59,
        },
        '60 - 69 Jahre alt': {
          val: featureProperties.Nummer_60_69,
          percent: featureProperties.Anteil_60_69,
        },
        '70 - 79 Jahre alt': {
          val: featureProperties.Nummer_70_79,
          percent: featureProperties.Anteil_70_79,
        },
        '80+ Jahre alt': {
          val: featureProperties.Nummer_80_und_aelter,
          percent: featureProperties.Anteil_80_und_aelter,
        },
      },
    },
    Migrationshintergrund: {
      value: '',
      data: {
        'Einwohner (Anzahl)': {
          val:
            featureProperties.Nummer_Deutschland +
            featureProperties.Nummer_EU27_Land +
            featureProperties.Nummer_Sonstige +
            featureProperties.Nummer_Sonstiges_Europa,
          percent: undefined,
        },
        Deutschland: {
          val: featureProperties.Nummer_Deutschland,
          percent: featureProperties.Anteil_Deutschland,
        },
        'EU-27': {
          val: featureProperties.Nummer_EU27_Land,
          percent: featureProperties.Anteil_EU27_Land,
        },
        'EU Sonstiges': {
          val: featureProperties.Nummer_Sonstige,
          percent: featureProperties.Anteil_Sonstige,
        },
        Sonstiges: {
          val: featureProperties.Nummer_Sonstiges_Europa,
          percent: featureProperties.Anteil_Sonstiges_Europa,
        },
      },
    },
    'Typ des Haushalts': {
      value: '',
      data: {
        'Haushalt (Anzahl)': {
          val:
            featureProperties.Nummer_Alleinerziehende_Elternteile +
            featureProperties.Nummer_Einpersonenhaushalte__Singlehaushalte_ +
            featureProperties.Nummer_Mehrpersonenhaushalte_ohne_Kernfamilie +
            featureProperties.Nummer_Paare_mit_Kind_ern_ +
            featureProperties.Nummer_Paare_ohne_Kind_er_,
          percent: undefined,
        },
        'Alleinerziehende Elternteile': {
          val: featureProperties.Nummer_Alleinerziehende_Elternteile,
          percent: featureProperties.Anteil_Alleinerziehende_Elternteile,
        },
        Einpersonenhaushalte: {
          val: featureProperties.Nummer_Einpersonenhaushalte__Singlehaushalte_,
          percent:
            featureProperties.Anteil_Einpersonenhaushalte__Singlehaushalte_,
        },
        'Mehrpersonenhaushalte ohne Kernfamilie': {
          val: featureProperties.Nummer_Mehrpersonenhaushalte_ohne_Kernfamilie,
          percent:
            featureProperties.Anteil_Mehrpersonenhaushalte_ohne_Kernfamilie,
        },
        'Paare mit Kinder': {
          val: featureProperties.Nummer_Paare_mit_Kind_ern_,
          percent: featureProperties.Anteil_Paare_mit_Kind_ern_,
        },
        'Paare ohne Kinder': {
          val: featureProperties.Nummer_Paare_ohne_Kind_er_,
          percent: featureProperties.Anteil_Paare_ohne_Kind_er_,
        },
      },
    },
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={i18n.t('General.PopulationData')}
      style={{ width: '60%' }}
    >
      <div className={infoTableStyles.modalContainer}>
        {Object.keys(populationInfo).map((category, index) => (
          <div key={category}>
            <h2
              className='bp4-heading'
              style={{ marginTop: index === 0 ? 0 : 50 }}
            >
              {category}
            </h2>
            <div className={infoTableStyles.rowContainer}>
              {Object.keys(populationInfo[category]['data']).map((kpi) => {
                const val = get(populationInfo, [category, 'data', kpi, 'val']);
                const percentage =
                  get(populationInfo, [category, 'data', kpi, 'percent']) || 0;

                return (
                  <div key={kpi} style={{ marginRight: 30, minWidth: 100 }}>
                    <p className={infoTableStyles.statistics}>
                      <h2
                        className='bp4-heading'
                        style={{
                          margin: '0 6px 0 0',
                        }}
                      >
                        {formatNumber(val)}
                      </h2>
                      <span style={{ marginBottom: 2 }}>
                        {percentage > 0 && `(${formatNumber(percentage)}%)`}
                      </span>
                    </p>
                    <p
                      className='bp4-text-muted bp4-text-small'
                      style={{
                        color: '#abb3bf',
                        minWidth: 150,
                      }}
                    >
                      {kpi}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default InfoTable;
