import i18n from 'i18next';
import getUserLocale from 'get-user-locale';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/translation.en.json';
import translationDE from './locales/translation.de.json';

const userLocale = getUserLocale().slice(0, 2);
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: userLocale === 'de' ? 'de' : 'en',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;
