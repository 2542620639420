import React from 'react';
import styles from '../styles/Loader.module.css';

const Loader = () => {
  return (
    <div className={styles.loadingPage}>
      <div className={styles.logo}>
        <img
          src='/images/logo.svg'
          width='150'
          height='150'
          className='pointer'
          alt='Loading...'
        />
      </div>
      <div className={styles.ldsSpinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
