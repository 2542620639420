import './styles/App.css';

// libraries
import './lib/i18n';
import { getAuth } from '@firebase/auth';
import firebase from './lib/clientFirebase';
import { useAuthState } from 'react-firebase-hooks/auth';

// pages
import Home from './pages/Home';
import Loader from './pages/Loader';
import Authentication from './pages/Auth';

function App() {
  console.log(process.env.REACT_APP_ENV_NAME);
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : user && user.accessToken ? (
        <Home />
      ) : (
        <Authentication />
      )}
    </div>
  );
}

export default App;
