import React from 'react';

// constants
import { MAP_STYLES } from '../lib/constants';

// styles
import { Popover2 } from '@blueprintjs/popover2';
import { Button, MenuItem, Position, Menu } from '@blueprintjs/core';

const InfoCard = ({ setMapStyleObj }) => {
  return (
    <div style={{ position: 'absolute', bottom: 30, right: 10 }}>
      <Popover2
        minimal
        content={
          <Menu>
            {MAP_STYLES.map((mapStylesObj) => (
              <MenuItem
                key={mapStylesObj.name}
                text={mapStylesObj.name}
                icon={mapStylesObj.icon}
                onClick={() => {
                  setMapStyleObj(mapStylesObj);
                }}
              />
            ))}
          </Menu>
        }
        position={Position.TOP_RIGHT}
      >
        <Button icon='layers' id='layerselector' large />
      </Popover2>
    </div>
  );
};

export default InfoCard;
