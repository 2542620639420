import i18n from 'i18next';

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

export const NUMBER_TYPES = {
  POPULATION: 'population',
  REVENUE: 'revenue',
  PURCHASING_POWER: 'purchasing',
  RENT: 'rent',
  GENERAL: 'general',
};

export const MAP_STYLES = [
  {
    name: i18n.t('MapStyle.Street'),
    url: 'mapbox://styles/niologic/ckzidiujv000d14pxc6mcna10',
    icon: 'locate'
  },
  {
    name: i18n.t('MapStyle.Satellite'),
    url: 'mapbox://styles/niologic/ck8t4ro26021g1iqo7u3ucfpr',
    icon: 'satellite'
  },
];

export const formatNumber = (num, type = NUMBER_TYPES.GENERAL) => {
  return num;
};

export const LOCATIONS = [
  {
    name: 'Nürnberg',
    lng: 11.081243797460075,
    lat: 49.44624157237868,
  },
  {
    name: 'Köln',
    lng: 6.960260268286248,
    lat: 50.937622355219105,
  },
  {
    name: 'Freiburg im Breisgau',
    lng: 7.847586268799205,
    lat: 47.994271971759986,
  },
];

export const RED_COLORS = [
  '#FFFFB2',
  '#FED976',
  '#FEB24C',
  '#FD8D3C',
  '#FC4E2A',
  '#E31A1C',
  '#B10026',
];

export const GREEN_COLORS = [
  '#FFFFCC',
  '#C7E9B4',
  '#7FCDBB',
  '#41B6C4',
  '#1D91C0',
  '#225EA8',
  '#0C2C84',
];

export const BLUE_TO_RED_COLORS = [
  '#0C2C84',
  '#225EA8',
  '#1D91C0',
  '#41B6C4',
  '#FD8D3C',
  '#FC4E2A',
  '#E31A1C',
  '#B10026',
]

export const DATA_RANGE = {
  microcentrality: {
    Köln: [0.0001078848343789, 0.0420813410015519],
    'Freiburg im Breisgau': [0.0001078848343789, 0.0420813410015519],
    Nürnberg: [0.0001078848343789, 0.0420813410015519],
  },
  shopper_revenue: {
    Köln: [0.0, 0.0372019443335161],
    'Freiburg im Breisgau': [0.0, 0.0372019443335161],
    Nürnberg: [0.0, 0.0372019443335161],
  },
  rent: {
    Köln: [4.03, 78.05],
    'Freiburg im Breisgau': [4.03, 78.05],
    Nürnberg: [4.03, 78.05],
  },
  population_density: {
    Köln: [0.0000009894791633072416, 0.0102999443868474],
    'Freiburg im Breisgau': [0.0000009894791633072416, 0.0102999443868474],
    Nürnberg: [0.0000009894791633072416, 0.0102999443868474],
  },
};

export const JENKS_DISTRIBUTION = {
  microcentrality: {
    Köln: [
      0.00463716428215196, 0.00782119406678786, 0.0104514039776818,
      0.0166879035151223, 0.0240232125124043, 0.0304073532257112,
    ],
    'Freiburg im Breisgau': [
      0.00463716428215196, 0.00782119406678786, 0.0104514039776818,
      0.0166879035151223, 0.0240232125124043, 0.0304073532257112,
    ],
    Nürnberg: [
      0.00463716428215196, 0.00782119406678786, 0.0104514039776818,
      0.0166879035151223, 0.0240232125124043, 0.0304073532257112,
    ],
  },
  shopper_revenue: {
    Köln: [
      0.000245075500103127, 0.000541781794496152, 0.00214617038444612,
      0.0057546283575615, 0.0134889233125179, 0.0195510436958088,
    ],
    'Freiburg im Breisgau': [
      0.000245075500103127, 0.000541781794496152, 0.00214617038444612,
      0.0057546283575615, 0.0134889233125179, 0.0195510436958088,
    ],
    Nürnberg: [
      0.000245075500103127, 0.000541781794496152, 0.00214617038444612,
      0.0057546283575615, 0.0134889233125179, 0.0195510436958088,
    ],
  },
  rent: {
    Köln: [
      9.07513856774327, 11.1444669736158, 11.9272865342027, 12.7038740414513,
      13.9271672972973, 22.5895156118812,
    ],
    'Freiburg im Breisgau': [
      9.07513856774327, 11.1444669736158, 11.9272865342027, 12.7038740414513,
      13.9271672972973, 22.5895156118812,
    ],
    Nürnberg: [
      9.07513856774327, 11.1444669736158, 11.9272865342027, 12.7038740414513,
      13.9271672972973, 22.5895156118812,
    ],
  },
  population_density: {
    Köln: [
      0.000124711974784918, 0.000443964735442675, 0.000665780514452079,
      0.00143430383530116, 0.00288568229419208, 0.00516951682727262,
    ],
    'Freiburg im Breisgau': [
      0.000124711974784918, 0.000443964735442675, 0.000665780514452079,
      0.00143430383530116, 0.00288568229419208, 0.00516951682727262,
    ],
    Nürnberg: [
      0.000124711974784918, 0.000443964735442675, 0.000665780514452079,
      0.00143430383530116, 0.00288568229419208, 0.00516951682727262,
    ],
  },
  changing_mobility_layer: {
    Köln: [-1, -0.66, -0.33, 0.00, 0.33, 0.66, 1],
    'Freiburg im Breisgau': [-1, -0.66, -0.33, 0.00, 0.33, 0.66, 1],
    Nürnberg: [-1, -0.66, -0.33, 0.00, 0.33, 0.66, 1],
  }
};

export const ALL_KPIS = {
  shopper: {
    Nürnberg: [
      {
        title: i18n.t('Metrics.Retail Revenue'),
        value: formatNumber(3538200000, NUMBER_TYPES.REVENUE),
      },
      {
        title: i18n.t('Metrics.Retail Purchasing Power'),
        value: formatNumber(3484300000, NUMBER_TYPES.PURCHASING_POWER),
      },
      {
        title: i18n.t('Metrics.Retail Centrality Index'),
        value: formatNumber(102, NUMBER_TYPES.GENERAL),
      },
    ],
    'Freiburg im Breisgau': [
      {
        title: i18n.t('Metrics.Retail Revenue'),
        value: formatNumber(1671300000, NUMBER_TYPES.REVENUE),
      },
      {
        title: i18n.t('Metrics.Retail Purchasing Power'),
        value: formatNumber(1416900000, NUMBER_TYPES.PURCHASING_POWER),
      },
      {
        title: i18n.t('Metrics.Retail Centrality Index'),
        value: formatNumber(118, NUMBER_TYPES.GENERAL),
      },
    ],
    Köln: [
      {
        title: i18n.t('Metrics.Retail Revenue'),
        value: formatNumber(7029100000, NUMBER_TYPES.REVENUE),
      },
      {
        title: i18n.t('Metrics.Retail Purchasing Power'),
        value: formatNumber(7446100000, NUMBER_TYPES.PURCHASING_POWER),
      },
      {
        title: i18n.t('Metrics.Retail Centrality Index'),
        value: formatNumber(94, NUMBER_TYPES.GENERAL),
      },
    ],
  },
  rent: {
    Nürnberg: [
      {
        title: `${i18n.t('Metrics.Business Rent Price')} (∅)`,
        value: formatNumber(10.6, NUMBER_TYPES.RENT),
      },
    ],
    'Freiburg im Breisgau': [
      {
        title: `${i18n.t('Metrics.Business Rent Price')} (∅)`,
        value: formatNumber(12.75, NUMBER_TYPES.RENT),
      },
    ],
    Köln: [
      {
        title: `${i18n.t('Metrics.Business Rent Price')} (∅)`,
        value: formatNumber(13.3, NUMBER_TYPES.RENT),
      },
    ],
  },
  population: {
    Nürnberg: [
      {
        title: i18n.t('Metrics.Population'),
        value: formatNumber(485992.35175, NUMBER_TYPES.POPULATION),
      },
      {
        title: i18n.t('Metrics.Retail Purchasing Power'),
        value: formatNumber(3484300000, NUMBER_TYPES.PURCHASING_POWER),
      },
      {
        title: i18n.t('Metrics.Retail Purchasing Power per Inhabitant'),
        value: formatNumber(7169.454390492885, NUMBER_TYPES.PURCHASING_POWER),
      },
    ],
    'Freiburg im Breisgau': [
      {
        title: i18n.t('Metrics.Population'),
        value: formatNumber(209929.96026, NUMBER_TYPES.POPULATION),
      },
      {
        title: i18n.t('Metrics.Retail Purchasing Power'),
        value: formatNumber(1416900000, NUMBER_TYPES.PURCHASING_POWER),
      },
      {
        title: i18n.t('Metrics.Retail Purchasing Power per Inhabitant'),
        value: formatNumber(6749.393932362764, NUMBER_TYPES.PURCHASING_POWER),
      },
    ],
    Köln: [
      {
        title: i18n.t('Metrics.Population'),
        value: formatNumber(1006280.70161, NUMBER_TYPES.POPULATION),
      },
      {
        title: i18n.t('Metrics.Retail Purchasing Power'),
        value: formatNumber(7446100000, NUMBER_TYPES.PURCHASING_POWER),
      },
      {
        title: i18n.t('Metrics.Retail Purchasing Power per Inhabitant'),
        value: formatNumber(7399.62516232956, NUMBER_TYPES.PURCHASING_POWER),
      },
    ],
  },
  mobilitychanges: {
    Nürnberg: [],
    'Freiburg im Breisgau': [],
    Köln: []
  }
};

export const TOUR_STEPS = [
  {
    title: i18n.t('TOUR_STEPS.STEP_ONE.TITLE'),
    content: i18n.t('TOUR_STEPS.STEP_ONE.CONTENT'),
    placement: 'center',
    target: 'body',
  },
  {
    title: i18n.t('TOUR_STEPS.STEP_TWO.TITLE'),
    content: i18n.t('TOUR_STEPS.STEP_TWO.CONTENT'),
    floaterProps: {
      disableAnimation: false,
    },
    spotlightPadding: 20,
    target: '.bp3-input-group',
  },
  {
    title: i18n.t('TOUR_STEPS.STEP_THREE.TITLE'),
    content: i18n.t('TOUR_STEPS.STEP_THREE.CONTENT'),
    floaterProps: {
      disableAnimation: false,
    },
    spotlightPadding: 20,
    target: '.bp3-tabs',
  },
  {
    title: i18n.t('TOUR_STEPS.STEP_FOUR.TITLE'),
    content: i18n.t('TOUR_STEPS.STEP_FOUR.CONTENT'),
    floaterProps: {
      disableAnimation: false,
    },
    spotlightPadding: 20,
    target: '#kpibox',
  },
  {
    title: i18n.t('TOUR_STEPS.STEP_FIVE.TITLE'),
    content: (
      <div>
        <img src='/images/tooltip.png' width='300px' height='180px' />
        <br />
        <p>{i18n.t('TOUR_STEPS.STEP_FIVE.CONTENT')}</p>
      </div>
    ),
    floaterProps: {
      disableAnimation: false,
    },
    placement: 'center',
    target: 'body',
  },
  {
    title: i18n.t('TOUR_STEPS.STEP_SIX.TITLE'),
    content: i18n.t('TOUR_STEPS.STEP_SIX.CONTENT'),
    floaterProps: {
      disableAnimation: false,
    },
    spotlightPadding: 20,
    target: '#usersettings',
  },
];

export const ALL_MACRO_KPIS = {
  population: {
    Nürnberg: [
      {
        title: i18n.t('Metrics.No Degree'),
        value: 28,
      },
      {
        title: i18n.t('Metrics.Apprenticeship'),
        value: 42,
      },
      {
        title: i18n.t('Metrics.University'),
        value: 10,
      },
    ],
    'Freiburg im Breisgau': [
      {
        title: i18n.t('Metrics.No Degree'),
        value: 30,
      },
      {
        title: i18n.t('Metrics.Apprenticeship'),
        value: 28,
      },
      {
        title: i18n.t('Metrics.University'),
        value: 19,
      },
    ],
    Köln: [
      {
        title: i18n.t('Metrics.No Degree'),
        value: 32,
      },
      {
        title: i18n.t('Metrics.Apprenticeship'),
        value: 35,
      },
      {
        title: i18n.t('Metrics.University'),
        value: 14,
      },
    ],
  },
};

export const MACRO_TOOLTIP = {
  Nürnberg: [
    {
      title: i18n.t('Metrics.No Degree'),
      value: 28,
    },
    {
      title: i18n.t('Metrics.Apprenticeship'),
      value: 42,
    },
    {
      title: i18n.t('Metrics.Technical College'),
      value: 9,
    },{
      title: i18n.t('Metrics.University of Cooperative Education'),
      value: 2,
    },
    {
      title: i18n.t('Metrics.University of Applied Sciences'),
      value: 7,
    },{
      title: i18n.t('Metrics.University'),
      value: 10,
    },
    {
      title: i18n.t('Metrics.Doctorate'),
      value: 2,
    },
  ],
  'Freiburg im Breisgau': [
    {
      title: i18n.t('Metrics.No Degree'),
      value: 30,
    },
    {
      title: i18n.t('Metrics.Apprenticeship'),
      value: 28,
    },
    {
      title: i18n.t('Metrics.Technical College'),
      value: 9,
    },{
      title: i18n.t('Metrics.University of Cooperative Education'),
      value: 2,
    },
    {
      title: i18n.t('Metrics.University of Applied Sciences'),
      value: 7,
    },{
      title: i18n.t('Metrics.University'),
      value: 19,
    },
    {
      title: i18n.t('Metrics.Doctorate'),
      value: 4,
    },
  ],
  Köln: [
    {
      title: i18n.t('Metrics.No Degree'),
      value: 32,
    },
    {
      title: i18n.t('Metrics.Apprenticeship'),
      value: 35,
    },
    {
      title: i18n.t('Metrics.Technical College'),
      value: 8,
    },{
      title: i18n.t('Metrics.University of Cooperative Education'),
      value: 2,
    },
    {
      title: i18n.t('Metrics.University of Applied Sciences'),
      value: 7,
    },{
      title: i18n.t('Metrics.University'),
      value: 14,
    },
    {
      title: i18n.t('Metrics.Doctorate'),
      value: 2,
    },
  ],
}

export const POI_SETTINGS = [
  {
    label: i18n.t('POI.MajorCategory.Shops'),
    icon: 'shop',
    enableAll: false,
    prevTagWidth: 0,
    childCategories: [
      {
        label: i18n.t('POI.MinorCategory.Others'),
        key: 'others',
        enabled: false,
        icon: 'viewpoint'
      },
      {
        label: i18n.t('POI.MinorCategory.Bookstore'),
        key: 'books',
        enabled: false,
        icon: 'library'
      },
      {
        label: i18n.t('POI.MinorCategory.Department Stores'),
        key: 'department_store',
        enabled: false,
        icon: 'shop'
      },
      {
        label: i18n.t('POI.MinorCategory.Kiosk'),
        key: 'kiosk',
        enabled: false,
        icon: 'information'
      },
      {
        label: i18n.t('POI.MinorCategory.Clothing Shops'),
        key: 'clothes',
        enabled: false,
        icon: 'clothing-store'
      },
      {
        label: i18n.t('POI.MinorCategory.Supermarket'),
        key: 'supermarket',
        enabled: false,
        icon: 'grocery'
      },
      {
        label: i18n.t('POI.MinorCategory.Jewelry Shops'),
        key: 'jewelry',
        enabled: false,
        icon: 'jewelry-store'
      },
      {
        label: i18n.t('POI.MinorCategory.Convenience Stores'),
        key: 'convenience',
        enabled: false,
        icon: 'convenience'
      },
      {
        label: i18n.t('POI.MinorCategory.Beverage Shops'),
        key: 'beverages',
        enabled: false,
        icon: 'alcohol-shop'
      },
      {
        label: i18n.t('POI.MinorCategory.Gift Shops'),
        key: 'gift',
        enabled: false,
        icon: 'art-gallery'
      },
      {
        label: i18n.t('POI.MinorCategory.Shoe Stores'),
        key: 'shoes',
        enabled: false,
        icon: 'shoe'
      },
      {
        label: i18n.t('POI.MinorCategory.Perfumery'),
        key: 'perfumery',
        enabled: false,
        icon: 'garden'
      },
    ],
  },
  {
    label: i18n.t('POI.MajorCategory.Restaurants and Cafés'),
    icon: 'glass',
    enableAll: false,
    prevTagWidth: 84,
    childCategories: [
      {
        label: i18n.t('POI.MinorCategory.Beer Garden'),
        key: 'biergarten',
        enabled: false,
        icon: 'beer'
      },
      {
        label: i18n.t('POI.MinorCategory.Bar'),
        key: 'bar',
        enabled: false,
        icon: 'bar'
      },
      {
        label: i18n.t('POI.MinorCategory.Café'),
        key: 'cafe',
        enabled: false,
        icon: 'cafe'
      },
      {
        label: i18n.t('POI.MinorCategory.Bakery'),
        key: 'bakery',
        enabled: false,
        icon: 'bakery'
      },
      {
        label: i18n.t('POI.MinorCategory.Fast Food Chain'),
        key: 'fast_food',
        enabled: false,
        icon: 'fast-food'
      },
      {
        label: i18n.t('POI.MinorCategory.Restaurant'),
        key: 'restaurant',
        enabled: false,
        icon: 'restaurant'
      },
      {
        label: i18n.t('POI.MinorCategory.Food Court'),
        key: 'food_court',
        enabled: false,
        icon: 'restaurant-noodle'
      },
      {
        label: i18n.t('POI.MinorCategory.Confectionery'),
        key: 'confectionery',
        enabled: false,
        icon: 'confectionery'
      },
    ],
  },
  {
    label: i18n.t('POI.MajorCategory.Transportation'),
    icon: 'train',
    enableAll: false,
    prevTagWidth: 189 + 84,
    childCategories: [
      {
        label: i18n.t('POI.MinorCategory.Parking Spots'),
        key: 'parking',
        enabled: false,
        icon: 'parking'
      },
      {
        label: i18n.t('POI.MinorCategory.Public Transport'),
        key: 'public_transport',
        enabled: false,
        icon: 'bus'
      },
    ],
  },
];

export const FLATTENED_POIs = POI_SETTINGS.reduce((acc, val) => acc.concat(...val.childCategories), [])
