import React from 'react';
import i18n from 'i18next';
import InfoCard from './InfoCard';
import { Popup } from 'react-map-gl';

const Tooltip = ({ showPopup, popupInfo, togglePopup }) => {
  const tooltipInfoList =
    popupInfo.info && popupInfo.info.source === 'grid_source'
      ? [
          {
            title: 'Shopper Revenue',
            value: popupInfo.info.properties.shopper_revenue,
          },
          {
            title: i18n.t('Metrics.Zonal Retail Revenue'),
            value: popupInfo.info.properties.local_retail_revenue,
          },
          {
            title: i18n.t('Metrics.Zonal Retail Purchasing Power'),
            value: popupInfo.info.properties.local_retail_purchasing_power,
          },
        ]
      : popupInfo.info && popupInfo.info.source === 'microcentrality_source'
      ? [
          {
            title: i18n.t('Metrics.Microcentrality'),
            value: popupInfo.info.properties.microcentrality,
          },
        ]
      : popupInfo.info && popupInfo.info.source === 'rent_source'
      ? [
          {
            title: i18n.t('Metrics.Rent Price'),
            value: popupInfo.info.properties.rent_price,
          },
        ]
      : popupInfo.info && popupInfo.info.source === 'population_source'
      ? [
          {
            title: i18n.t('Metrics.Zonal Population Density'),
            value: popupInfo.info.properties.population_density,
          },
          {
            title: i18n.t('Metrics.Zonal Population'),
            value: popupInfo.info.properties.actual_population,
          },
          {
            title: i18n.t('Metrics.Zonal Retail Purchasing Power'),
            value: popupInfo.info.properties.local_purchasing_power,
          },
        ]
      : popupInfo.info && popupInfo.info.source === 'changing_mobility_source'
      ? [
          {
            title: i18n.t('Metrics.Changes relative to 2019'),
            value: popupInfo.info.properties.Change_Gesamt * 100,
          },
        ]
      : [];
  return (
    showPopup && (
      <Popup
        latitude={popupInfo.lat}
        longitude={popupInfo.lng}
        closeButton={false}
        closeOnClick={true}
        onClose={() => togglePopup(false)}
        anchor='left'
      >
        <div style={{ minWidth: 155, opacity: 0.9 }}>
          {popupInfo.info &&
            popupInfo.info.source === 'microcentrality_source' && (
              <>
                <h2
                  className='bp4-heading'
                  style={{ marginTop: 0, marginBottom: 5 }}
                >
                  {popupInfo.info.properties.shoe_store_name}
                </h2>
                <p style={{ fontWeight: 500 }}>
                  {popupInfo.info.properties.road}{' '}
                  {popupInfo.info.properties.house_number &&
                    popupInfo.info.properties.house_number.length > 0 &&
                    popupInfo.info.properties.house_number}
                </p>
              </>
            )}
          {popupInfo.info && popupInfo.info.source === 'poi_source' && (
            <>
              <h2
                className='bp4-heading'
                style={{ marginTop: 0, marginBottom: 5 }}
              >
                {popupInfo.info.properties.name}
              </h2>
              <p style={{ fontWeight: 500 }}>
                {popupInfo.info.properties.road}
              </p>
            </>
          )}
          <InfoCard infoList={tooltipInfoList} />
        </div>
      </Popup>
    )
  );
};

export default Tooltip;
