import React from 'react';
import i18n from 'i18next';
import { getMinRange, getMaxRange, formatNumber } from '../lib/helper';
import { Card, Elevation } from '@blueprintjs/core';
import { RED_COLORS, GREEN_COLORS, BLUE_TO_RED_COLORS } from '../lib/constants';

const Legend = ({ layerName, siteLocation }) => {
  const LEGENDS =
    layerName === 'shopper'
      ? [
          {
            title: `Shopper Revenue (%)`,
            min: getMinRange('shopper_revenue', siteLocation),
            max: getMaxRange('shopper_revenue', siteLocation),
            colorScheme: GREEN_COLORS,
          },
          {
            title: i18n.t('Metrics.Micro-centrality'),
            min: getMinRange('microcentrality', siteLocation),
            max: getMaxRange('microcentrality', siteLocation),
            colorScheme: RED_COLORS,
          },
        ]
      : layerName === 'rent'
      ? [
          {
            title: `${i18n.t('Metrics.Rent Price')} (EUR/sqm)`,
            min: getMinRange('rent', siteLocation),
            max: getMaxRange('rent', siteLocation),
            colorScheme: GREEN_COLORS,
          },
        ]
      : layerName === 'population'
      ? [
          {
            title: i18n.t('Metrics.Population Density'),
            min: getMinRange('population_density', siteLocation),
            max: getMaxRange('population_density', siteLocation),
            colorScheme: GREEN_COLORS,
          },
        ]
      : [
          {
            title: i18n.t('Metrics.Changes relative to 2019'),
            min: -100,
            max: 100,
            colorScheme: BLUE_TO_RED_COLORS,
          },
        ];

  return (
    <Card
      interactive={false}
      elevation={Elevation.TWO}
      style={{
        position: 'absolute',
        bottom: 40,
        left: 10,
      }}
    >
      {LEGENDS.map((legend, index) => (
        <div
          key={legend.title}
          style={{ paddingBottom: index === LEGENDS.length - 1 ? 0 : 25 }}
        >
          <h5
            className=''
            style={{ textTransform: 'uppercase', margin: 0, marginBottom: 5 }}
          >
            {legend.title}
          </h5>
          <ul
            style={{ minWidth: 210, margin: 0, padding: 0, listStyle: 'none' }}
          >
            <li
              style={{
                float: 'left',
                margin: '5px 0 10px 0',
                // color: 'rgb(24, 32, 38)',
                color: '#abb3bf',
                fontSize: 14,
              }}
            >
              {' '}
              {legend.title === i18n.t('Metrics.Changes relative to 2019')
                ? '-100%'
                : formatNumber(legend.min)}
            </li>
            <li
              style={{
                float: 'right',
                margin: '5px 0 10px 0',
                // color: 'rgb(24, 32, 38)',
                color: '#abb3bf',
                fontSize: 14,
              }}
            >
              {' '}
              {legend.title === i18n.t('Metrics.Changes relative to 2019') &&
                '+'}
              {formatNumber(legend.max)}
              {legend.title === i18n.t('Metrics.Changes relative to 2019') &&
                '%'}
            </li>
            <li className='graph count_441'>
              <div className='colors'>
                {legend.colorScheme.map((color) => (
                  <div
                    key={color}
                    className='quartile'
                    style={{ backgroundColor: color }}
                  ></div>
                ))}
              </div>
            </li>
          </ul>
        </div>
      ))}
    </Card>
  );
};

export default Legend;
