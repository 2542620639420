import React from 'react';

// lib
import i18n from 'i18next';
import { get } from 'lodash';
import { formatNumber } from '../lib/helper';

import EDUCATION_DATA from '../data/education_level_complete.json';

// styles
import { Dialog } from '@blueprintjs/core';
import infoTableStyles from '../styles/InfoTable.module.css';

const InfoTable = ({ isOpen = false, handleClose, siteLocation }) => {
  if (siteLocation.length === 0) return null;

  const currSiteEduData = EDUCATION_DATA[siteLocation];

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={i18n.t('General.EducationLevel')}
      style={{ width: '60%' }}
    >
      <div className={infoTableStyles.modalContainer}>
        {Object.keys(currSiteEduData).map((category, index) => (
          <div key={category}>
            <h2
              className='bp4-heading'
              style={{ marginTop: index === 0 ? 0 : 30 }}
            >
              {category}
            </h2>
            <div className={infoTableStyles.rowContainer}>
              {Object.keys(currSiteEduData[category]['data']).map((kpi) => {
                const val =
                  typeof currSiteEduData[category]['data'][kpi] === 'string'
                    ? get(currSiteEduData, [category, 'data', kpi])
                    : get(currSiteEduData, [category, 'data', kpi, 'val']);
                const percentage =
                  get(currSiteEduData, [category, 'data', kpi, 'percent']) || 0;

                return (
                  <div key={kpi} style={{ marginRight: 30, minWidth: 100 }}>
                    <p className={infoTableStyles.statistics}>
                      <h2
                        className='bp4-heading'
                        style={{
                          margin: '0 6px 0 0',
                        }}
                      >
                        {formatNumber(val)}
                      </h2>
                      <span style={{ marginBottom: 2 }}>
                        {percentage > 0 && `(${formatNumber(percentage)}%)`}
                      </span>
                    </p>
                    <p
                      className='bp4-text-muted bp4-text-small'
                      style={{
                        color: '#abb3bf',
                        minWidth: 150,
                      }}
                    >
                      {kpi}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default InfoTable;
