import { RED_COLORS, GREEN_COLORS, BLUE_TO_RED_COLORS, FLATTENED_POIs } from '../lib/constants';
import {
  getJenksNaturals,
} from './helper';

const PAINT_PARAMS = {
  'fill-outline-color': [
    'case',
    ['boolean', ['feature-state', 'hover'], false],
    'red',
    '#919191',
  ],
};

export const gridSourceDefination = {
  type: 'vector',
  url: 'mapbox://niologic.34k2tmvq',
  minzoom: 1,
  maxzoom: 20,
};

export const microcentralitySourceDefination = {
  type: 'vector',
  url: 'mapbox://niologic.cks4fr3t',
  minzoom: 1,
  maxzoom: 20,
};

export const changingMobilitySourceDefination = {
  type: 'vector',
  url: 'mapbox://niologic.92tsnn2w',
  minzoom: 1,
  maxzoom: 20,
}

export const rentSourceDefination = {
  type: 'vector',
  url: 'mapbox://niologic.329v5ac7',
  minzoom: 1,
  maxzoom: 20,
};

export const populationInfoSourceDefination = {
  type: 'vector',
  url: 'mapbox://niologic.4mbtp0gx',
  minzoom: 1,
  maxzoom: 20,
};

export const poiSourceDefination = {
  type: 'vector',
  url: 'mapbox://niologic.6pnjr0le',
  minzoom: 1,
  maxzoom: 20,
};

export const microcentralityLayerDefination = (currSiteName) => ({
  id: 'microcentrality_layer',
  type: 'circle',
  'source-layer': 'microcentrality',
  layout: {},
  paint: {
    'circle-radius': 4,
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#f2db0a',
      [
        'step',
        ['get', 'microcentrality'],
        ...getJenksNaturals('microcentrality', currSiteName, RED_COLORS),
      ],
    ],
    'circle-stroke-width': 1,
    'circle-stroke-color': '#FFF',
  },
});

export const gridLayerDefinition = (currSiteName) => ({
  id: 'grid_layer',
  'source-layer': 'shopper_kpi',
  type: 'fill',
  beforeId: 'poi_layer',
  paint: {
    ...PAINT_PARAMS,
    'fill-color': [
      'case',
      ['boolean', ['==', ['get', 'shopper_revenue'], 0], false],
      'rgba(0,0,0,0)',
      [
        'step',
        ['to-number', ['get', 'shopper_revenue']],
        ...getJenksNaturals('shopper_revenue', currSiteName, GREEN_COLORS),
      ],
    ],
  },
});

export const rentLayerDefinition = (currSiteName) => ({
  id: 'rent_layer',
  'source-layer': 'rent',
  type: 'fill',
  beforeId: 'poi_layer',
  paint: {
    ...PAINT_PARAMS,
    'fill-color': [
      'case',
      ['boolean', ['==', ['get', 'rent_price'], 0], false],
      'rgba(0,0,0,0)',
      [
        'step',
        ['to-number', ['get', 'rent_price']],
        ...getJenksNaturals('rent', currSiteName, GREEN_COLORS),
      ],
    ],
  },
});

export const populationLayerDefinition = (currSiteName) => ({
  id: 'population_layer',
  'source-layer': 'pop_info_2_gdf-d1aq8k',
  type: 'fill',
  beforeId: 'poi_layer',
  paint: {
    ...PAINT_PARAMS,
    'fill-color': [
      'case',
      ['boolean', ['==', ['get', 'population_density'], 0], false],
      'rgba(0,0,0,0)',
      [
        'step',
        ['to-number', ['get', 'population_density']],
        ...getJenksNaturals('population_density', currSiteName, GREEN_COLORS),
      ],
    ],
  },
});

export const changingMobilityLayerDefinition = (currSiteName) => ({
  id: 'changing_mobility_layer',
  'source-layer': 'mobi_20220116only_sa_h3_9-8oi86s',
  type: 'fill',
  beforeId: 'poi_layer',
  paint: {
    ...PAINT_PARAMS,
    'fill-color': [
      'case',
      ['boolean', ['<=', ['get', 'Change_Gesamt'], -5], false],
      'rgba(0,0,0,0)',
      [
        'step',
        ['to-number', ['get', 'Change_Gesamt']],
        ...getJenksNaturals('changing_mobility_layer', currSiteName, BLUE_TO_RED_COLORS),
      ],
    ],
  },
});

export const poiLayerDefination = () => ({
  id: 'poi_layer',
  'source-layer': 'complete_poi_list_with_addres-dgo556',
  type: 'symbol',
  beforeId: 'microcentrality_layer',
  layout: {
    'icon-image': [
      'case',
      ...FLATTENED_POIs.reduce(
        (acc, poi) =>
          acc.concat([
            ['boolean', ['==', ['get', 'minor_category'], poi.key], false],
            poi.icon,
          ]),
        []
      ),
      'airport',
    ],
    'icon-offset': [0, 0],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-size': 1,
  },
  paint: {
    'icon-opacity': [
      'case',
      ...FLATTENED_POIs.reduce(
        (acc, poi) =>
          acc.concat([
            ['boolean', ['==', ['get', 'minor_category'], poi.key], false],
            1,
          ]),
        []
      ),
      0,
    ],
    'icon-halo-color': '#ffffff',
  },
});
