import React from 'react';

// constants
import { POI_SETTINGS } from '../lib/constants';

// styles
import {
  Card,
  Elevation,
  Checkbox,
  Tag,
  Position,
  Switch,
  Alignment,
  Icon,
} from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import styles from '../styles/Utilities.module.css';

const POIControls = ({
  currPOILabel,
  poiControlSetting,
  handleEnabledChange,
}) => {
  if (!poiControlSetting) {
    return null;
  }

  return (
    <Card
      interactive={false}
      id='kpibox'
      elevation={Elevation.TWO}
      style={{
        width: 250,
        maxHeight: 'calc(100vh - 50px)',
        overflowY: 'auto',
      }}
    >
      {poiControlSetting
        .filter((poi) => poi.label === currPOILabel)
        .map((majorCategory) => (
          <>
            <Switch
              key={majorCategory.label}
              checked={majorCategory.enableAll}
              label={majorCategory.label}
              alignIndicator={Alignment.RIGHT}
              style={{ fontWeight: 700, marginBottom: 20 }}
              onChange={() => {
                const updatedNewSettings = poiControlSetting.map(
                  (oldSetting) => {
                    if (oldSetting.label === majorCategory.label) {
                      return {
                        ...oldSetting,
                        enableAll: !oldSetting.enableAll,
                        childCategories: oldSetting.childCategories.map(
                          (childCategories) => ({
                            ...childCategories,
                            enabled: !oldSetting.enableAll,
                          })
                        ),
                      };
                    } else {
                      return oldSetting;
                    }
                  }
                );

                handleEnabledChange(updatedNewSettings);
              }}
            />
            {majorCategory.childCategories.map((minorCategory) => (
              <div
                className={styles.flexSpaceBtw}
                onMouseOver={() => {
                  const elem = document.getElementById(
                    `minor-category-${minorCategory.label}-only`
                  );
                  if (elem) elem.style.opacity = 1;
                }}
                onMouseLeave={() => {
                  const elem = document.getElementById(
                    `minor-category-${minorCategory.label}-only`
                  );
                  if (elem) elem.style.opacity = 0;
                }}
              >
                <Checkbox
                  key={minorCategory.label}
                  checked={minorCategory.enabled}
                  label={minorCategory.label}
                  onChange={() => {
                    const updatedNewSettings = poiControlSetting.map(
                      (oldSetting) => {
                        if (oldSetting.label === majorCategory.label) {
                          const updatedChildCategories =
                            oldSetting.childCategories.map((childCategory) => {
                              if (childCategory.label === minorCategory.label) {
                                return {
                                  ...childCategory,
                                  enabled: !childCategory.enabled,
                                };
                              } else {
                                return childCategory;
                              }
                            });
                          const areAllChildEnabled = updatedChildCategories
                            .map(
                              (updatedChildCategory) =>
                                updatedChildCategory.enabled
                            )
                            .reduce((acc, val) => acc && val, true);

                          return {
                            ...oldSetting,
                            enableAll: areAllChildEnabled,
                            childCategories: updatedChildCategories,
                          };
                        } else {
                          return oldSetting;
                        }
                      }
                    );

                    handleEnabledChange(updatedNewSettings);
                  }}
                />
                <p
                  id={`minor-category-${minorCategory.label}-only`}
                  className={styles.only}
                  onClick={() => {
                    const updatedNewSettings = poiControlSetting.map(
                      (oldSetting) => {
                        if (oldSetting.label === majorCategory.label) {
                          const updatedChildCategories =
                            oldSetting.childCategories.map((childCategory) => {
                              if (childCategory.label === minorCategory.label) {
                                return {
                                  ...childCategory,
                                  enabled: true,
                                };
                              } else {
                                return {
                                  ...childCategory,
                                  enabled: false,
                                };
                              }
                            });

                          return {
                            ...oldSetting,
                            enableAll: false,
                            childCategories: updatedChildCategories,
                          };
                        } else {
                          return oldSetting;
                        }
                      }
                    );

                    handleEnabledChange(updatedNewSettings);
                  }}
                >
                  Only
                </p>
              </div>
            ))}
          </>
        ))}
    </Card>
  );
};

const POIControlBox = ({ poiControlSetting, handleEnabledChange }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 60,
        right: 10,
      }}
    >
      {POI_SETTINGS.map((poiCategory) => (
        <Popover2
          key={poiCategory.label}
          minimal
          content={
            <POIControls
              currPOILabel={poiCategory.label}
              poiControlSetting={poiControlSetting}
              handleEnabledChange={handleEnabledChange}
            />
          }
          position={Position.BOTTOM_RIGHT}
        >
          <Tag
            rightIcon={
              <Icon
                icon={poiCategory.icon}
                style={{ color: 'rgb(171, 179, 191)' }}
              />
            }
            id='poi-control'
            large
            interactive
            style={{
              backgroundColor: '#FFF',
              color: '#182026',
              marginLeft: 10,
            }}
          >
            {poiCategory.label}
          </Tag>
        </Popover2>
      ))}
    </div>
  );
};

export default POIControlBox;
