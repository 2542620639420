import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { getAuth, EmailAuthProvider } from '@firebase/auth';
import styles from '../styles/About.module.css';

const uiConfig = {
  signInSuccessUrl: '/',
  signInOptions: [
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
      disableSignUp: {
        status: true,
        adminEmail: "",
        helpLink: "https://www.niologic.com/de/",
      },
    },
  ],
};

export default function Authentication() {
  const auth = getAuth();
  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <a href='https://www.niologic.com/'>
          <img
            src='https://premergency.ai/wp-content/uploads/2019/01/niologic-logo-1.png'
            alt='Niologic GmbH'
            width='375'
            style={{ display: 'block', border: 'none', marginBottom: 100 }}
          />
        </a>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </main>
    </div>
  );
}
