import i18n from 'i18next';
import chroma from 'chroma-js';
import {
  DATA_RANGE,
  ALL_KPIS,
  JENKS_DISTRIBUTION,
  ALL_MACRO_KPIS,
} from './constants';
import { MenuItem } from '@blueprintjs/core';

const formatNumEuropean = (num) => new Intl.NumberFormat('en-US').format(num);

function convertToInternationalCurrencySystem(labelValue) {
  return Number(labelValue) >= 1.0e6
    ? formatNumEuropean((Number(labelValue) / 1.0e6).toFixed(2)) + ' Mio'
    : // Three Zeroes for Thousands
    Number(labelValue) >= 1.0e3
    ? formatNumEuropean(Number(labelValue).toFixed(2))
    : Number(labelValue) >= 1 || Number(labelValue) <= -1
    ? Number(labelValue).toFixed(2)
    : Number(labelValue);
}
export const formatNumber = (num, title = '') => {
  if (num === undefined || num === null) return num;

  let currency = convertToInternationalCurrencySystem(num);

  if (typeof currency === 'string') {
    const last3 = currency.slice(-3);
    if (last3 === '.00') {
      currency = currency.slice(0, -3);
    }
  }

  if (currency < 1) {
    currency = Number(currency)
      .toFixed(20)
      .match(/^-?\d*\.?0*\d{0,1}/)[0];

    const last20 = currency.slice(-20);
    if (last20 === '00000000000000000000') {
      currency = Number(currency).toFixed(2);
    }
  }

  if (
    title === i18n.t('Metrics.Retail Revenue') ||
    title === i18n.t('Metrics.Retail Purchasing Power per Inhabitant') ||
    title === i18n.t('Metrics.Retail Purchasing Power') ||
    title === i18n.t('Metrics.Zonal Retail Purchasing Power') ||
    title === i18n.t('Metrics.Zonal Retail Revenue')
  ) {
    currency = currency + ' EUR';
  } else if (
    title === `${i18n.t('Metrics.Business Rent Price')} (∅)` ||
    title === i18n.t('Metrics.Rent Price')
  ) {
    currency = currency + ' EUR/sqm';
  } else if (
    title === i18n.t('Metrics.No Degree') ||
    title === i18n.t('Metrics.Apprenticeship') ||
    title === i18n.t('Metrics.University') ||
    title === i18n.t('Metrics.Technical College') ||
    title === i18n.t('Metrics.University of Cooperative Education') ||
    title === i18n.t('Metrics.University of Applied Sciences') ||
    title === i18n.t('Metrics.Doctorate') ||
    title === i18n.t('Metrics.Changes relative to 2019')
  ) {
    currency = currency + '%';
  }

  return i18n.language === 'en'
    ? currency.replaceAll(',', ' ')
    : currency.replaceAll(',', ' ').replaceAll('.', ',');
};

const mergeAlternately = (array1 = [], array2 = []) =>
  array1.reduce((acc, val, index) => {
    return acc.concat(val, array2[index]);
  }, []).filter(elem => elem !== undefined);

export function zip(arrays) {
  return Array.apply(null, Array(arrays[0].length)).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i];
    });
  });
}
export function generate_linear_color_scale(
  colorsUnused,
  lo,
  hi,
  num_colors,
  layerType
) {
  const colors =
    layerType === 'grid'
      ? [
          '#FFFFCC',
          '#C7E9B4',
          '#7FCDBB',
          '#41B6C4',
          '#1D91C0',
          '#225EA8',
          '#0C2C84',
        ]
      : [
          '#FFFFB2',
          '#FED976',
          '#FEB24C',
          '#FD8D3C',
          '#FC4E2A',
          '#E31A1C',
          '#B10026',
        ];
  const cols = colors.map((col) => chroma(col).alpha(0.55).css());
  const stops = [...Array(num_colors).keys()].map(
    (x) => (x / (num_colors - 1)) * (hi - lo) + lo
  );

  return zip([cols, stops]);
}
export const getColorScale = (color1, color2, steps, lo, hi, layerType) => {
  const zip = generate_linear_color_scale(
    [color1, color2],
    lo,
    hi,
    steps,
    layerType
  );
  const res = zip.reduce((acc, val) => acc.concat(...val), []);
  res.pop();
  console.log(res);

  return res;
};

export const getJenksNaturals = (layerName, siteName, colorArray) => {
  const breakpoints = JENKS_DISTRIBUTION[`${layerName}`][`${siteName}`];
  const cols = colorArray.map((col) => chroma(col).alpha(0.55).css());
  // cols.unshift('rgba(255,255,255,0)');
  // console.clear()
  // console.log(layerName, siteName, breakpoints, cols);
  // console.log(mergeAlternately(cols, breakpoints));
  return mergeAlternately(cols, breakpoints);
};

export const filterLocation = (query, location) => {
  return location.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

export function getCursor({ isHovering, isDragging }) {
  return isDragging ? 'grabbing' : isHovering ? 'pointer' : 'default';
}

export const locationRenderer = (location, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      selected={modifiers.active}
      key={location.name}
      onClick={handleClick}
      text={location.name}
    />
  );
};

export const getMinRange = (layerName, siteName) => {
  if (!layerName || !siteName) {
    return 0;
  } else {
    return DATA_RANGE[`${layerName}`][`${siteName}`][0];
  }
};

export const getMaxRange = (layerName, siteName) => {
  if (!layerName || !siteName) {
    return 1;
  } else {
    return DATA_RANGE[`${layerName}`][`${siteName}`][1];
  }
};

export const getKPIList = (layerName, siteName) => {
  if (!layerName || !siteName) {
    return [];
  } else {
    return ALL_KPIS[`${layerName}`][`${siteName}`];
  }
};

export const getMacroPopulationKPIs = (layerName, siteName) => {
  if (!layerName || !siteName) {
    return [];
  } else {
    return ALL_MACRO_KPIS[`${layerName}`][`${siteName}`];
  }
};
