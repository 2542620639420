import React from 'react';
import i18n from 'i18next';
import InfoCard from './InfoCard';
import { getKPIList, getMacroPopulationKPIs } from '../lib/helper';
import { Card, Elevation, Button } from '@blueprintjs/core';

const KPIbox = ({
  layerName,
  siteLocation,
  toggleShowPopulationDetails,
}) => {
  const KPIs = getKPIList(layerName, siteLocation);
  const macroPopulationKPIs = getMacroPopulationKPIs(
    'population',
    siteLocation
  );

  return (
    <>
      {layerName === 'population' && macroPopulationKPIs.length > 0 && (
        <Card
          interactive={false}
          id='kpibox'
          elevation={Elevation.TWO}
          style={{
            position: 'absolute',
            bottom: 115.5 + 40 + 227 + 20,
            left: 10,
            width: 250,
          }}
        >
          <InfoCard infoList={macroPopulationKPIs} />
          <Button
            icon='info-sign'
            id='showPopDetailBtn'
            minimal
            fill
            onClick={toggleShowPopulationDetails}
            style={{marginTop: 12}}
          >
            {i18n.t('General.MoreInformation')}
          </Button>
        </Card>
      )}
      {KPIs.length > 0 && (
        <Card
          interactive={false}
          id='kpibox'
          elevation={Elevation.TWO}
          style={{
            position: 'absolute',
            bottom: `calc(${
              layerName === 'shopper' ? 215.8 : 115.5
            }px + 40px + 10px)`,
            left: 10,
            width: 250,
          }}
        >
          <InfoCard infoList={KPIs} />
        </Card>
      )}
    </>
  );
};

export default KPIbox;
