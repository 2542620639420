import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import MapboxLanguage from "@mapbox/mapbox-gl-language";
// import { useRouter } from 'next/router';
import { getAuth, signOut } from '@firebase/auth';
import { getFirestore, doc, getDoc } from '@firebase/firestore';
import firebase from '../lib/clientFirebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import ReactMapGL, { Source, Layer, FlyToInterpolator } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

// components
import {
  Navbar,
  Button,
  MenuItem,
  Alignment,
  Tabs,
  Tab,
  Position,
  Menu,
} from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import Joyride from 'react-joyride';
import Tooltip from '../elements/Tooltip';
import KPIbox from '../elements/KPIbox';
import Legend from '../elements/Legend';
import InfoTable from '../elements/InfoTable';
import POIControlBox from '../elements/POIControlBox';
import MapStyleSwitch from '../elements/MapStyleSwitch';
import PopulationInfoTable from '../elements/PopulationInfoTable';
import Loader from './Loader';

// styles
import 'mapbox-gl/dist/mapbox-gl.css';
import { Popover2 } from '@blueprintjs/popover2';

// lib
import { MAPBOX_TOKEN, LOCATIONS, TOUR_STEPS, MAP_STYLES, POI_SETTINGS } from '../lib/constants';
import { filterLocation, getCursor, locationRenderer } from '../lib/helper';
import {
  // source
  poiSourceDefination,
  gridSourceDefination,
  microcentralitySourceDefination,
  rentSourceDefination,
  populationInfoSourceDefination,
  changingMobilitySourceDefination,
  // layers,
  poiLayerDefination,
  microcentralityLayerDefination,
  gridLayerDefinition,
  rentLayerDefinition,
  populationLayerDefinition,
  changingMobilityLayerDefinition
} from '../lib/layers';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function Home() {
  const auth = getAuth();
  const db = getFirestore(firebase);
  // const router = useRouter();
  const [user, loading, error] = useAuthState(auth);
  const [currSiteName, setSiteName] = useState('');
  const [accessableSites, setAccessableSites] = useState([]);

  useEffect(() => {
    console.log('home');

    async function fetchMyAPI() {
      try {
        const currUserInDB = (await getDoc(doc(db, 'users', user.uid))).data();
        const accessableSites = (
          await getDoc(doc(db, `group/${currUserInDB.group[0]}`))
        ).data().sites;

        if (accessableSites.length > 0) {
          const accessableSitesInfo = LOCATIONS.filter((site) =>
            accessableSites.includes(site.name)
          );
          // console.clear();
          setSiteName(accessableSitesInfo[0].name);
          setAccessableSites(accessableSitesInfo);
          setViewport((vw) => ({
            ...vw,
            longitude: accessableSitesInfo[0].lng,
            latitude: accessableSitesInfo[0].lat,
            zoom: 14,
            transitionDuration: 3000,
            transitionInterpolator: new FlyToInterpolator(),
          }));
        }
      } catch (err) {
        console.error(err);
      }
    }

    fetchMyAPI();
  }, []);

  const mapRef = React.useRef(null);
  const [showPopulationDetails, setShowPopulationDetails] = React.useState(false)
  const [mapStyleObj, setMapStyleObj] = useState(MAP_STYLES[0])
  const [showPopup, togglePopup] = React.useState(false);
  const [selectedTab, selectTab] = React.useState('shopper');
  const [popupInfo, setPopupInfo] = React.useState({});
  const [detailedPopulationInfo, setDetailedPopulationInfo] = React.useState(null);
  const [showPopulationInfo, setShowPopulationInfo] = React.useState(false);
  const [poiControlSettings, setPOIControlSettings] = useState(POI_SETTINGS)
  const [viewport, setViewport] = React.useState({
    longitude: 11.081243797460075,
    latitude: 49.44624157237868,
    zoom: 1,
  });

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.addControl(
        new MapboxLanguage({
          defaultLanguage: i18n.language || 'de'
        })
      );
    }
  }, [mapRef]);

  useEffect(() => {
    if (detailedPopulationInfo) {
      setShowPopulationInfo(true)
    }
  }, [detailedPopulationInfo])

  const enableTooltip = (e) => {
    const selectedFeature = e.features && e.features[0];
    if (!selectedFeature) return;

    if (
      selectedFeature &&
      (selectedFeature.source === 'poi_source' ||
        selectedFeature.source === 'grid_source' ||
        selectedFeature.source === 'rent_source' ||
        selectedFeature.source === 'population_source' ||
        selectedFeature.source === 'microcentrality_source' ||
        selectedFeature.source === 'changing_mobility_source')
    ) {
      setPopupInfo({ lng: e.lngLat[0], lat: e.lngLat[1], info: e.features[0] });
      togglePopup(true);
    }
  };

  const togglePopulationDetailsModal = () => { setShowPopulationDetails(oldState => !oldState) }
  const closePopulationInfo = () => {
    setShowPopulationInfo(false);
    setDetailedPopulationInfo(null);
  }

  if (loading || error) return <Loader />;

  if (!user) {
    // router.push('/auth');
  }

  const _onMapClicked = evt => {
    const selectedFeature = evt.features && evt.features[0];
    console.log(selectedFeature)

    if (!selectedFeature) return;

    if (selectedFeature.layer.id === "population_layer") {
      setDetailedPopulationInfo(selectedFeature.properties)
    }
  }

  const accessableSiteList = accessableSites.map((site) => site.name);
  const accessablePOIList = poiControlSettings.reduce((acc, val) => acc.concat(...val.childCategories), []).filter(poi => poi.enabled).map(poi => poi.key);
  const interactiveLayerIds = [
    ...(selectedTab === 'shopper' ? ['microcentrality_layer', 'grid_layer'] : []),
    ...(selectedTab === 'mobilitychanges' ? ['changing_mobility_layer'] : []),
    ...(selectedTab === 'rent' ? ['rent_layer'] : []),
    ...(selectedTab === 'population' ? ['population_layer'] : []),
    'poi_layer'
  ];

  return (
    <>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <img
            src='/images/logo.svg'
            width='30'
            height='30'
            onClick={() => {
              // router.push('/about');
            }}
            className='pointer'
          />
          <Navbar.Divider />
          <Tabs
            id='TabsExample'
            onChange={selectTab}
            selectedTabId={selectedTab}
          >
            <Tab id='shopper' title={i18n.t('Navbar.Shopper')} />
            <Tab id='mobilitychanges' title={i18n.t('Navbar.MobilityChanges')} />
            <Tab id='rent' title={i18n.t('Navbar.Rent')} />
            <Tab id='population' title={i18n.t('Navbar.Population')} />
          </Tabs>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Suggest
            items={accessableSites}
            inputValueRenderer={(item) => item.name}
            itemPredicate={filterLocation}
            itemRenderer={locationRenderer}
            noResults={<MenuItem disabled={true} text='No results.' />}
            popoverProps={{ minimal: true }}
            onItemSelect={(selectedItem) => {
              setSiteName(selectedItem.name);
              setViewport((oldViewport) => ({
                ...oldViewport,
                longitude: selectedItem.lng,
                latitude: selectedItem.lat,
                zoom: 14,
                transitionDuration: 5000,
                transitionInterpolator: new FlyToInterpolator(),
              }));
            }}
          ></Suggest>
          <div style={{ height: 10, width: 200 }}></div>
          <Navbar.Divider />
          <Popover2
            minimal
            content={
              <Menu>
                <MenuItem
                  text='Log out'
                  icon='log-out'
                  onClick={() => {
                    signOut(auth);
                  }}
                />
              </Menu>
            }
            position={Position.BOTTOM_RIGHT}
          >
            <Button icon='user' id='usersettings' minimal large />
          </Popover2>
        </Navbar.Group>
      </Navbar>
      <ReactMapGL
        ref={mapRef}
        {...viewport}
        width='100vw'
        height='calc(100vh - 50px)'
        onClick={_onMapClicked}
        onHover={enableTooltip}
        mapStyle={mapStyleObj.url}
        onMouseLeave={() => togglePopup(false)}
        onMouseOut={() => togglePopup(false)}
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onLoad={() => {
          console.clear();
          console.log('language: ', i18n.language);
          const map = mapRef.current.getMap();
          map.removeLayer('poi-label');
        }}
        getCursor={getCursor}
        interactiveLayerIds={interactiveLayerIds}
      >
        <Source id='microcentrality_source' {...microcentralitySourceDefination}>
          {selectedTab === 'shopper' && (
            <Layer
              {...microcentralityLayerDefination(currSiteName)}
              filter={[
                'match',
                ['get', 'area'],
                [...accessableSiteList, ''],
                true,
                false,
              ]}
            />
          )}
        </Source>
        <Source id='poi_source' {...poiSourceDefination}>
          <Layer
              {...poiLayerDefination()}
              filter={[
                'all',
                [
                  'match',
                  ['get', 'area'],
                  [...accessableSiteList, ''],
                  true,
                  false,
                ],
                [
                  'match',
                  ['get', 'minor_category'],
                  [...accessablePOIList, ''],
                  true,
                  false,
                ]
              ]}
            />
        </Source>
        <Source
          id='changing_mobility_source'
          {...changingMobilitySourceDefination}
        >
          {selectedTab === 'mobilitychanges' && (
            <Layer
              {...changingMobilityLayerDefinition(currSiteName)}
              filter={[
                'match',
                ['get', 'area'],
                [...accessableSiteList, ''],
                true,
                false,
              ]}
            />
          )}
        </Source>
        <Source id='grid_source' {...gridSourceDefination}>
          {selectedTab === 'shopper' && (
            <Layer
              id='grid_layer'
              {...gridLayerDefinition(currSiteName)}
              filter={[
                'match',
                ['get', 'area'],
                [...accessableSiteList, ''],
                true,
                false,
              ]}
            />
          )}
        </Source>
        <Source id='rent_source' {...rentSourceDefination}>
          {selectedTab === 'rent' && (
            <Layer
              {...rentLayerDefinition(currSiteName)}
              filter={[
                'match',
                ['get', 'area'],
                [...accessableSiteList, ''],
                true,
                false,
              ]}
            />
          )}
        </Source>
        <Source id='population_source' {...populationInfoSourceDefination}>
          {selectedTab === 'population' && (
            <Layer
              {...populationLayerDefinition(currSiteName)}
              filter={[
                'match',
                ['get', 'area'],
                [...accessableSiteList, ''],
                true,
                false,
              ]}
            />
          )}
        </Source>
        <Tooltip
          showPopup={showPopup}
          popupInfo={popupInfo}
          togglePopup={togglePopup}
          siteLocation={currSiteName}
          layerName={selectedTab}
        />
      </ReactMapGL>
      <MapStyleSwitch setMapStyleObj={setMapStyleObj} />
      <POIControlBox
        poiControlSetting={poiControlSettings}
        handleEnabledChange={setPOIControlSettings}
      />
      <KPIbox
        siteLocation={currSiteName}
        layerName={selectedTab}
        toggleShowPopulationDetails={togglePopulationDetailsModal}
      />
      <Legend
        siteLocation={currSiteName}
        layerName={selectedTab}
      />
      <InfoTable
        isOpen={showPopulationDetails}
        handleClose={togglePopulationDetailsModal}
        siteLocation={currSiteName}
      />
      <PopulationInfoTable
        isOpen={showPopulationInfo}
        handleClose={closePopulationInfo}
        featureProperties={detailedPopulationInfo}
      />
      <Joyride
        steps={TOUR_STEPS}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        locale={{
          back: i18n.t('TOUR.Back'),
          next: i18n.t('TOUR.Next'),
          skip: i18n.t('TOUR.Skip'),
          last: i18n.t('TOUR.Last'),
          close: i18n.t('TOUR.Close'),
          open: i18n.t('TOUR.Open the Dialog'),
        }}
      />
    </>
  );
}

export default Home;
