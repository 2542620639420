import React from 'react';
import {formatNumber} from '../lib/helper'

const InfoCard = ({ infoList = [] }) => {
  return (
    infoList.length > 0 &&
    infoList.map((info, index) => (
      <div key={info.title}>
        <h2
          className='bp4-heading'
          style={{ marginBottom: 0, marginTop: index === 0 ? 0 : 17 }}
        >
          {formatNumber(info.value, info.title)}
        </h2>
        <p
          className='bp4-text-muted bp4-text-small'
          style={{
            marginBottom: index === infoList.length - 1 ? 0 : 10,
            color: '#abb3bf',
          }}
        >
          {info.title}
        </p>
      </div>
    ))
  );
};

export default InfoCard;
